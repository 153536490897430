@import './src/scss/variables';
@import './src/scss/app';

.widget__input .ng-scope {
	display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .5rem;
}

.widget__result-text a {
	display: inline-flex;
}